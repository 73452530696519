





















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { formatDate, formatTime } from '../utils/dateUtils'
import { vxm } from '@/store'
import { DateTime } from 'luxon'
import PortalData from '@/utils/PortalData'
import { AxiosError } from 'axios'

// todo: maybe also display:
// - service place
// - booking ticket
// - tyre hotel info (v2)

@Component({})
export default class BookingView extends Vue {
  private loading = true
  private error = ''
  private booking: any = {}
  private showCancelDialog = false
  private showConfirmedDialog = false
  private firstCanCancelTime: DateTime = null
  private firstCanModifyTime: DateTime = null

  // private booking: Booking;

  private get url(): string {
    if (!this.$route.params.ticket || !this.$route.params.secret) {
      return ''
    }
    return '/v4/booking/bookings/' + this.$route.params.ticket + '/' + this.$route.params.secret
  }

  private get portalId(): string {
    return this.$route.params.portal
  }

  public mounted(): void {
    this.load()
  }

  private load() {
    // todo: move to vxm
    const portalData = new PortalData(this.$axios)
    portalData.loadPortalId(this.portalId, (err: AxiosError) => {
      if (err) {
        console.error('Error fetching portal:', err, err.response?.data)
        this.error = err.response?.data?.error?.message || 'Unknown error'
      } else {
        // document.title = this.portalData.portal.name
        vxm.locale.setLocale(portalData.locale)
        this.firstCanCancelTime = portalData.firstCanCancelTime
        this.firstCanModifyTime = portalData.firstCanModifyTime
        this.loadBooking()
      }
    })
  }

  private loadBooking() {
    if (this.url) {
      this.$axios
        .get(this.url)
        .then((response) => {
          this.booking = response.data.data
          this.loading = false
        })
        .catch((err) => {
          console.error('Error loading booking:', err)
          this.error = '' + this.$t('Error loading booking')
          this.loading = false
        })
    } else {
      this.loading = false
    }
  }

  private get localTimeZone(): string {
    return vxm.locale?.locale?.timezone || 'UTC'
  }

  private get localTimeFormat(): string {
    return vxm.locale?.locale?.timeFormat || 'sv'
  }

  private get siteCountryName(): string {
    return vxm.locale?.locale?.countryName || ''
  }

  private get isCancelled() {
    return this.booking?.status === 'Cancelled'
  }

  private get alert() {
    const alert = {
      type: '',
      message: '',
    }
    if (this.booking) {
      if (this.booking.status === 'Cancelled') {
        alert.type = 'warning'
        alert.message = this.$t('The booking is cancelled')
      } else if (!this.booking.isConfirmed) {
        alert.type = 'info'
        alert.message = this.$t('The booking is not confirmed')
      }
    }
    return alert.type === '' ? null : alert
  }

  // todo: also display end time, i.e. 07:30 - 08:00
  private get displayTime(): string {
    if (this.booking?.isDropIn) {
      return this.$t('c:booking:Drop-in')
    }
    if (this.booking?.time) {
      const t = DateTime.fromISO(this.booking.time, { zone: 'UTC' })
      return formatTime(t, this.localTimeZone, this.localTimeFormat)
    } else {
      return ''
    }
  }

  private get displayDate(): string {
    if (this.booking?.time) {
      const t = DateTime.fromISO(this.booking.time, { zone: 'UTC' })
      return formatDate(t, this.localTimeZone, this.localTimeFormat)
    } else {
      return ''
    }
  }

  private get primaryService() {
    if (this.booking && this.booking.work) {
      for (let i = 0; i < this.booking.work.length; i++) {
        const service = this.booking.work[i].service
        if (service.type === 'Primary') {
          return service
        }
      }
    }
    return {}
  }

  private get addonServices() {
    const result = []
    if (this.booking && this.booking.work) {
      for (let i = 0; i < this.booking.work.length; i++) {
        const service = this.booking.work[i].service
        if (service.type !== 'Primary') {
          service.key = this.booking.work[i].id
          result.push(service)
        }
      }
    }
    return result
  }

  private get place() {
    return this.primaryService?.place || {}
  }

  private clickConfirm() {
    const url = this.url + '/confirm'
    this.$axios
      .post(url)
      .then((_response) => {
        this.load()
        this.showConfirmedDialog = true
      })
      .catch((err) => {
        console.error('Error confirming booking:', err)
        this.error = this.$t('Error confirming booking')
      })
  }

  private clickChangeTime() {
    if (!this.canChangeTime) {
      alert(this.$t("c:booking:Unfortunately it's too late to change your time now"))
      return
    }
    this.$router.push({
      name: 'Bookings/Modify',
      params: {
        id: '' + this.booking.portalId,
        ticket: '' + this.booking.ticket,
        secret: '' + this.booking.secret,
      },
    })
  }

  private clickCancelBooking() {
    if (!this.canCancel) {
      alert(this.$t("c:booking:Unfortunately it's too late to cancel your time now"))
      return
    }
    this.showCancelDialog = true
  }

  private clickCancelNo() {
    this.showCancelDialog = false
  }

  private clickCancelYes() {
    const url = this.url + '/cancel'
    this.$axios
      .put(url)
      .then((_response) => {
        this.showCancelDialog = false
        this.load()
      })
      .catch((err) => {
        console.error('Error cancelling booking:', err)
        this.error = this.$t('Error cancelling booking')
      })
  }

  private get actionMessage() {
    switch (this.$route.params.action) {
      case 'create':
        return {
          title: this.$t('Thank you for your booking'),
          body: this.$t(
            'We\'ve sent you a message with your booked time, and will send a reminder when the booked time is approaching',
          ),
        }
      case 'modify':
        return {
          title: this.$t('Your booking has been modified'),
          body: this.$t(
            'We\'ve sent you a message with your new time, and will send a reminder when the booked time is approaching',
          ),
        }
      default:
        return null
    }
  }

  private get cancelButtonClass() {
    return this.canCancel ? '' : 'disabled'
  }

  private get changeTimeButtonClass() {
    return this.canChangeTime ? '' : 'disabled'
  }

  private get canCancel() {
    return this.checkForesight('cancel')
  }

  private get canChangeTime() {
    return this.checkForesight('modify')
  }

  private checkForesight(type: string) {
    if (!this.booking.time) {
      return false
    }
    const bookingTime = DateTime.fromISO(this.booking.time, { zone: 'UTC' })
    const minTime = type === 'cancel' ? this.firstCanCancelTime : this.firstCanModifyTime
    return bookingTime > minTime
  }
}
